import gql from 'graphql-tag';

export default gql`
query getAllPluginDataOfFolder($folderId: Int!) {
  plugins(where: {folder_id: {_eq: $folderId}}) {
    enabled
    handle
    settings
  }
}
`;
